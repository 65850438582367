import type { Ref } from 'vue'

export function layoutByDevice() {
  return useDevice().isDesktop ? 'desktop' : 'mobile'
}

export const useLayoutSize = ({
  header,
}: {
  header: Ref<HTMLElement | null>
}) => {
  const layoutStore = useLayoutStore()

  const { height } = useElementSize(header)

  watchEffect(() => {
    layoutStore.headerHeight = height.value
  })

  onUnmounted(() => {
    layoutStore.headerHeight = 0
  })

  return {
    //
  }
}
